@import url(./styles/font.scss);
@import url(./styles/animation.scss);

*{
  font-family: 'Pretendard';
  font-weight: 400;
}


$nav-height : 90px;

@mixin GrowUpMixIn($name, $height) {
  @keyframes #{$name} {
    from {
      height :0;
    }
    to {
      height:$height;
    }
  }
}

@include GrowUpMixIn(GrowUp75,75px);
@include GrowUpMixIn(GrowUp200,200px);

.container {
  width :1120px;
  margin:0 auto;
}

.section{
  padding-top: 110px;
  &.on{
    .section__category{
      animation: RiseUp .3s forwards;
      animation-delay: 0s;
    }
    .section__title{
      animation: RiseUp .3s forwards;
      animation-delay: .3s;
    }
    .section__detail{
      animation: RiseUp .3s forwards;
      animation-delay: .6s;
    }
  }
}

.section__category{
  font-size: 16px;
  font-weight: 700;
  color: #bababa;
  margin-bottom:23px;
  opacity: 0;
  animation-delay: 0s;
  will-change: transform,opacity;
}

.section__title{
  font-size: 38px;
  font-weight: 700;
  color: #000;
  margin-bottom:40px;
  opacity: 0;
  animation-delay: .3s;
  will-change: transform,opacity;
}

.section__detail{
  font-size: 22px;
  line-height: 1.73;
  color: #000;
  opacity: 0;
  animation-delay: .6s;
  will-change: transform,opacity;
}

//SECTION1
.section1{
  background-color: #f8f9fc;
  padding-bottom: 70px;
}

.section1__bottom-wrap{
  display: flex;
  justify-content:flex-end;
  padding-top:30px;
}

.section1__illust-box{
  width: 500px;
  height: 431px;
  border-radius: 8px;
  box-shadow: 10px 10px 50px 0 rgba(0, 21, 90, 0.12);
  background-color: #fff;
  position:relative;
  &.on{
    .section1__illust{
      &--1{
        animation:BounceIn 1s forwards;
        animation-delay: .3s;
      }
      &--2{
        animation:fadeInRight 1s forwards;
        animation-delay: .6s;
      }
      &--3{
        animation:fadeInLeft 1s forwards;
        animation-delay: .9s;
      }
    }
  }
}

.section1__illust{
  position:absolute;
  opacity: 0;
  &--1{
    left :39px;
    bottom: 30px;
  }
  &--2{
    right :25px;
    bottom:45px;
  }
  &--3{
    left:25px;
    top:29px;
  }
}

//SECTION2
.section2{
  background-color: #fff;
  padding-bottom:80px;
}
.section2__bottom-wrap{
  display: flex;
  justify-content: space-between;
  padding-top:50px;
}
.section2__status-wrap{
  display: flex;
  align-self: flex-end;
}
.section2__status-item{
  padding-top:58px;
  position:relative;
  &:nth-of-type(1){
    margin-right:86px;
  }
}

.section2__status-top-wrap{
  position:absolute;
  top:0;
  height :58px;
  display: flex;
  align-items: flex-end;
  padding-bottom:20px;
  &--column{
    align-items: flex-start;
    flex-direction: column;
  }
}
.section2__status-top-border{
  border: 2px solid #000;
  width:180px;
  background-color: #000;
  margin-top:10px;
}
.section2__right-wrap{
  flex:1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 650px) {
    justify-content: flex-start;
  }
}
.section2__graph-item-image{
  background-image: url(./assets/images/p-3-illust-01.png);
  width:181px;
  height:219px;
}
.section2__graph-item{
  width : 85px;
}
.section2__status-item-detail{
  font-size: 16px;
  line-height: 1.75;
  color: #000;
}
.section2__status-item-title{
  margin-bottom:15px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  &--blue {
    color: #00a6e9;
  }
}

.section2__status-item-sub-title{
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom:14px;
}

.section2__statistics-wrap{
  display: flex;
  flex-direction: column;
  width :505px;
  height :490px;
  box-shadow: 10px 10px 50px 0 rgba(0, 21, 90, 0.12);
  background-color: #FFF;
  // background-image: url(./assets//images/p-3-img.png);
  // background-size: cover;
  // background-position: center;
  padding:50px;
  border-radius: 8px;
}
.section2__statistics-title{
  font-size: 18px;
  font-weight: 700;
  line-height: 1.56;
  color: #000;
}
.section2__statistics-percent{
  font-size: 50px;
  font-weight: 900;
  color: #00a6e9;
}
.section2__graph-wrap{
  display: flex;
  align-items: flex-end;
  margin-top:39px;
  &.on {
    .section2__graph-bar {
      animation-duration: .9s;
      animation-fill-mode: forwards;
      &--2020{
        animation-name: GrowUp75;
        animation-delay: .3s;
      }
      &--2021{
        animation-name: GrowUp200;
        animation-delay: .6s;
      }
    }
  }
}
.section2__graph-bar{
  width: 76px;
  opacity: 0.46;
  background-color: #fff;
  margin-bottom:10px;
}
.section2__total-price{
  // width:76px;
  font-size: 24px;
  font-weight: 900;
  color: #000;
  margin-bottom:8px;
}
.section2__total-year{
  font-size: 15px;
  color: #000;
}

.section2__graph-bar--2020{
  background-image: url(./assets/images/p-3-illust-02.png);
  width:69px;
  height:79px;
}

.section2__graph-bar--2021{
  background-image: url(./assets/images/p-3-illust-03.png);
  width:69px;
  height:142px;
}

.section3 {
  @extend .section1;
}

.section3__bottom-wrap{
  display: flex;
  justify-content: space-between;
  padding-top:52px;
  &.on{
    .section3__process-item{
      animation: RiseUp forwards .3s;
      &:nth-of-type(1){
        animation-delay: .9s;
      }
      &:nth-of-type(2){
        animation-delay: 1.2s;
      }
      &:nth-of-type(3){
        animation-delay: 1.5s;
      }
    }
  }
}
.section3__process-list{
  padding-top:20px;
}
.section3__process-item{
  position: relative;
  padding-left:60px;
  padding-right:60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 90px;
  border-radius: 6px;
  box-shadow: 10px 10px 50px 0 rgba(0, 21, 90, 0.09);
  background-color: #fff;
  margin-bottom:30px;
  opacity: 0;
  &:nth-last-of-type(1){
    margin-bottom:0;
  }
  img {
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    left:30px;
  }
}
.section3__process-txt{
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.section3__illust-wrap{
  padding:35px 50px;
  border-radius: 8px;
  box-shadow: 10px 10px 50px 0 rgba(0, 21, 90, 0.12);
  background-color: #fff;
}
.section3__illust{
  &--pc{
    width: 400px;
  }
  &--mobile {
    display:none;
  }
}
//SECTION4
.section4 {
  background-color: #fff;
  padding-bottom:150px;
}
.section4__card-wrap{
  padding-top:120px;
  display: flex;
  justify-content: flex-end;
}
.section4__card{
  width: 390px;
  height: 350px;
  border-radius: 8px;
  padding:42px;
  box-shadow: 10px 10px 50px 0 rgba(0, 21, 90, 0.09);
  background-color: #fff;
  &:nth-of-type(1){
    margin-right:25px;
  }
}
.section4__card-icon{
  width:65px;
  height:65px;
  padding:10px;
  border-radius: 16px;
  border: solid 1px #e8f0fb;
  margin-bottom:30px;
}
.section4__card-title{
  font-size: 25px;
  font-weight: bold;
  color: #000;
  margin-bottom:12px;
}
.section4__card-txt{
  font-size: 18px;
  line-height: 1.67;
  color: #000;
}

.section5{
  background-color: #f8f9fc;
  padding-bottom:150px;
}
.section5__card-wrap{
  @extend .section4__card-wrap;
}
.section5__card {
  @extend .section4__card;
}
.section5__card-icon{
  @extend .section4__card-icon;
}
.section5__card-title{
  @extend .section4__card-title;
}
.section5__card-txt{
  @extend .section4__card-txt;
}
//CONTACT
.contact{
  height : 600px;
  background-image: url(./assets/images/contact-bg-img.png);
  background-position: center;
  background-size: 100%;
  background-attachment: fixed;
  padding-top:200px;
}
.contact__message{
  display: block;
  font-size: 35px;
  font-weight: 700;
  color: #fff;
  margin-bottom:60px;
}
.contact__send-mail{
  display: block;
  width:200px;
  height:50px;
  line-height: 50px;
  text-align: center;
  background-color: #00a6e9;
  color:#fff !important;
  border-radius: 5px;
  font-size :15px;
}
//FOOTER
.footer{
  height: 177px;
  background-color: #1a1a1a;
  display: flex;
  .container{
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
}
//NAV
.nav{
  position: fixed;
  top:0;
  left:0;
  height:90px;
  width :100%;
  z-index: 10;

  .container {
    display: flex;
    height :100%;
    justify-content: space-between;
  }
  transition: background 0.2s linear;
  &.on{
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    background-color:  #fff;
    .nav__btns{
      color : #000
    }
  }
}
.nav__logo {
  width :150px;
}
.nav__btns{
  display: flex;
  align-items: center;
  font-size: 15px;
  color:#fff;
  
}
.nav__btn{
  &:nth-last-of-type(1){
    margin-left:80px;
  }
}

//HEADER
.header{
  position:relative;
  background-image: url("./assets/images/header-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  padding-top:calc(#{$nav-height} + 228px);
  padding-bottom : 100px;
  animation: BackgroundCloseUp 5s forwards;
}

.header__sub-title{
  font-size: 29px;
  color: #fff;
  font-weight: 400;
  margin-bottom:28px;
  animation-duration: .5s;
}

.header__title{
  font-size: 55px;
  line-height: 1.27;
  color: #fff;
  animation-delay: .3s;
  animation-duration: .5s;
  strong{
    font-weight: 700;
  }
  margin-bottom:260px;
}

.header__more-wrap{
  display: flex;
  align-items: center;
  animation-delay: .6s;
  animation-duration: .5s;
}

.header__more-txt{
  font-size: 16px;
  color: #fff;
  margin-left:8px;
}


@media screen and (max-width: 1180px) {
	.container{
    width :100%;
    padding-left : 25px;
    padding-right :25px;
  }
  .header{
    background-image: url(./assets/images/p-1-bg-img-m@3x.png);
    padding-bottom: 50px;
  }

  .header__sub-title{
    font-size:2.6vw;
  }
  .header__title{
    font-size:4.3vw;
  }

  .section__category{
    font-size:1.7vw;
  }
  .section__title {
    font-size:3.757vw;
  }
  .section2__bottom-wrap{
    flex-direction: column;
  }
  .section2__statistics-wrap{
    margin-bottom: 3vw;
  }
}

@media screen and (max-width: 650px) {
  .pc{
    display:none;
  }
  .header{
    padding-top: 230px;
  }
  .header__sub-title{
    font-size:18px;
  }
  .header__title{
    font-size: 31px;
    margin-bottom:230px;
    line-height: 1.39;
  }
  .nav__logo{
    width :100px;
  }
  .nav__btns{
    display:none;
  }
  .section__category{
    font-size:12px;
  }
  .section__title {
    font-size:25px;
  }
  .section__detail{
    font-size:15px;
  }
  .section1{
    padding-top:60px;
  }
  .section1__bottom-wrap{
    padding-top:145px;
  }
  .section1__illust-box{
    width :250px;
    height :207px;
    background-color: transparent;
    box-shadow: none;
  }
  .section1__illust--pc{
    display:none;
  }
  .section1__illust--mobile{
    opacity: 1;
    width:250px;
  }
  .section2__statistics-wrap{
    padding:25px;
    width:100%;
    margin-bottom:90px;
  }
  .section2__total-price{
    font-size: 22px;
  }
  .section2__statistics-title{
    font-size: 15px;
    margin-bottom:25px;
  }
  .section2__statistics-percent{
    font-size: 40px;
  }
  .section2__graph-wrap{
    padding:0;
  }
  .section2__status-wrap{
    width:100%;
    flex-direction: column;
  }
  .section2__status-item{
    width:100%;
    padding-top :0;
    &:nth-of-type(1){
      margin-right :0;
      margin-bottom:82px;
      .section2__status-top-wrap img {
        display: none;
      }
    }
  }
  .section2__status-top-wrap{
    width:100%;
    padding-top:10px;
    height:auto;
    position:relative;
  }
  .section2__status-item-title{
    border-top: 3px solid #000;
    padding-top:20px;
  }
  .section2__status-top-border{
    display:none;
  }
  .section3__bottom-wrap{
    flex-direction: column;
    padding-left:23px;
    padding-right:23px;
  }
  .section3__process-item{
    width :100%;
  }
  .section3__process-txt{

  }
  .section3__illust-wrap{
    padding:0;
    box-shadow: none;
    padding-top:80px;
    background-color: transparent;
  }
  .section3__illust{
    &--pc{
      display:none;
    }
    &--mobile{
      display:block;
      margin:0 auto;
    }
  }
  .section4{
    padding-bottom:80px;
  }
  .section4__card-wrap{
    padding-top:60px;
    flex-direction: column;
  }
  .section4__card{
    width :100%;
    height :auto;
    padding:30px;
    &:nth-of-type(1){
      margin-right:0;
      margin-bottom:20px;
    }
  }
  .section4__card-title{
    font-size:20px;
  }
  .section4__card-txt{
    font-size:15px;
  }
  .section5{
    padding-bottom:80px;
  }
  .contact {
    padding-top:180px;
    height:500px;
  }
  .contact__message{
    font-size: 25px;
    text-align: center;
    margin-bottom:33px;
  }
  .contact__send-mail{
    margin:0 auto;
  }
}
